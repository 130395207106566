import {lazy} from "react";

export const AbsenceRoutes = lazy(() => import("@/layouts/AbsenceRoutes"));
export const BonusRoutes = lazy(() => import("@/layouts/BonusRoutes"));

export const CompensationElementsRoutes = lazy(() => import("@/layouts/CompensationElementsRoutes"));
export const CongesLeaveMonitoring = lazy(() => import("pag_collabo/absence-and-leaves/absence-leave-monitoring/CongesLeaveMonitoring"));
export const ProvisionLeaveMonitoring = lazy(() => import("pag_collabo/absence-and-leaves/absence-leave-monitoring/ProvisionLeaveMonitoring"));
export const ProvisionalBalance = lazy(() => import("pag_collabo/absence-and-leaves/absence-leave-monitoring/ProvisionalBalance"));
export const PlanningCalendar = lazy(() => import("pag_collabo/absence-and-leaves/absence-leave-monitoring/planningCalendar/PlanningCalendar"));
export const TableAbsenceOverview = lazy(() => import("pag_collabo/collabo-details/collabo-menu/leave/table/TableAbsenceOverview"));
export const Synthesis = lazy(() => import("pag_collabo/absence-and-leaves/synthesis/Synthesis"));
export const Absence = lazy(() => import("pag_collabo/validations/absence/Absence"));
export const NoteDeFrais = lazy(() => import("pag_collabo/validations/note_de_frais/NoteDeFrais"));
export const AbsenceDetail = lazy(() => import("pag_collabo/validations/absence/AbsenceDetail"));
export const Bonus = lazy(() => import("pag_collabo/compensation-elements/bonus/Bonus"));
export const ActiveBonuses = lazy(() => import("pag_collabo/compensation-elements/bonus/ActiveBonuses"));
export const AllDeduction = lazy(() => import("pag_collabo/compensation-elements/regulation/AllDeduction"));
export const ExpensesReport = lazy(() => import("pag_collabo/salary-items/expense-report/ExpensesReport"));
export const OtherSalaryItems = lazy(() => import("pag_collabo/salary-items/others/OtherSalaryItems"));
export const SalaryAdvance = lazy(() => import("pag_collabo/salary-items/SalaryAdvance"));

export const HeureSup = lazy(() => import("pag_collabo/temps-travail/HeureSup"));
export const Calendar = lazy(() => import("pag_collabo/absence-and-leaves/calendar/Calendar"));
export const AbsenceLeaveMonitoring = lazy(() => import("pag_collabo/absence-and-leaves/absence-leave-monitoring/AbsenceLeaveMonitoring"));
export const OtherAbsence = lazy(() => import("pag_collabo/absence-and-leaves/other-absence/OtherAbsence"));
export const CompanyFolders = lazy(() => import("pag_collabo/folders/Folders"));
export const CompanyTrash = lazy(() => import("pag_collabo/folders/Trash"));
export const CollaboFolders = lazy(() => import("pag_collabo/folders/collabo/CollaboFolders"));
export const MonthPayslip = lazy(() => import("pag_collabo/payslip/MonthPayslip"));
export const HistoryPayslip = lazy(() => import("pag_collabo/payslip/HistoryPayslip"));
export const PaySlipDispatchingModal = lazy(() => import("pag_collabo/payslip/PaySlipDispatchingModal"));
export const AccountingRouters = lazy(() => import("@/layouts/AccountingRouters"));
export const SalaryPaymentsRouters = lazy(() => import("@/layouts/SalaryPaymentsRouters"));
export const PaymentOrders = lazy(() => import("pag_collabo/accounting/PaymentOrders"));
export const PaymentState = lazy(() => import("pag_collabo/accounting/PaymentState"));
export const BankTransfer = lazy(() => import("pag_collabo/accounting/BankTransfer"));
export const MobileTransfer = lazy(() => import("pag_collabo/accounting/MobileTransfer"));
export const BankManagement = lazy(() => import("pag_collabo/accounting/BankManagement"));
export const BankFormModal = lazy(() => import("pag_collabo/accounting/modals/BankFormModal"));
export const PayrollStatement = lazy(() => import("pag_collabo/accounting/PayrollStatements"));
export const AccountingStatement = lazy(() => import("pag_collabo/accounting/AccountingStatements"));
export const Summary = lazy(() => import("pag_collabo/declaration-and-taxes/Summary"));
export const Cnps = lazy(() => import("pag_collabo/declaration-and-taxes/Cnps"));
export const PayrollTaxes = lazy(() => import("pag_collabo/declaration-and-taxes/PayrollTaxes"));
export const Taxes = lazy(() => import("pag_collabo/declaration-and-taxes/Taxes"));
export const AIB = lazy(() => import("pag_collabo/declaration-and-taxes/AIB"));
export const CollaboDirectory = lazy(() => import("pag_collabo/teams/collabo-directory/CollaboDirectory"));
export const CollaboRegister = lazy(() => import("pag_collabo/teams/collabo-register/CollaboRegister"));
export const OrganizationChart = lazy(() => import("pag_collabo/teams/organization-chart/OrganizationChart"));


//// Start Collabo details route
export const CollaboDetailsRoute = lazy(() => import("@/layouts/CollaboDetailsRoute"));
export const PaysheetDoc = lazy(() => import("pag_collabo/collabo-details/PaysheetDoc"));

// Start Collabo Profile Resume
export const ProfileResume = lazy(() => import("pag_collabo/collabo-details/collabo-menu/overview/ProfileResume"));
// End Collabo Profile Resume

// Start Collabo salary items details
export const SalaryItems = lazy(() => import("pag_collabo/collabo-details/collabo-menu/salary-items/SalaryItems"));
export const CollaboDetailSalaryBonus = lazy(() => import("pag_collabo/collabo-details/collabo-menu/salary-items/salary-submenus/SalaryBonus"));
export const CollaboDetailSalaryAdvance = lazy(() => import("pag_collabo/collabo-details/collabo-menu/salary-items/salary-submenus/SalaryAdvance"));
export const CollaboDetailUserSalaryAcompte = lazy(() => import("pag_collabo/collabo-details/collabo-menu/salary-items/salary-submenus/UserSalaryAcompte"));
export const CollaboDetailUserDeduction = lazy(() => import("pag_collabo/collabo-details/collabo-menu/salary-items/salary-submenus/SalaryDeduction"));
export const CollaboDetailUserIndemnity = lazy(() => import("pag_collabo/collabo-details/collabo-menu/salary-items/salary-submenus/SalaryIndemnity"));
// End Collabo salary items details

// Start Collabo leaves  details
export const Leave = lazy(() => import("pag_collabo/collabo-details/collabo-menu/leave/Leave"));
export const CollaboDetailLeavesOverview = lazy(() => import("pag_collabo/collabo-details/collabo-menu/leave/leave-submenu/Overview"));
export const CollaboDetailLeavesPaidLeave = lazy(() => import("pag_collabo/collabo-details/collabo-menu/leave/leave-submenu/PaidLeave"));
export const CollaboDetailLeavesAbsence = lazy(() => import("pag_collabo/collabo-details/collabo-menu/leave/leave-submenu/Absence"));
export const CollaboDetailLeavesRestTime = lazy(() => import("pag_collabo/collabo-details/collabo-menu/leave/leave-submenu/RestTime"));
// End  Collabo leaves  details


// Start Collabo contrat details
export const Contrat = lazy(() => import("pag_collabo/collabo-details/collabo-menu/contrat/Contrat"));
export const ContractType = lazy(() => import("pag_collabo/collabo-details/collabo-menu/contrat/contract-submenu/ContractType"));
export const JobInfo = lazy(() => import("pag_collabo/collabo-details/collabo-menu/contrat/contract-submenu/JobInfo"));
export const WorkTime = lazy(() => import("pag_collabo/collabo-details/collabo-menu/contrat/contract-submenu/WorkTime"));
// End Collabo contrat details


// Collabo Folders
export const UserFolders = lazy(() => import("pag_collabo/collabo-details/collabo-menu/folders/UserFolders"));
// End Folders

//// End Collabo details route


export const CollaboRoutes = lazy(() => import("@/layouts/CollaboRoutes"));
export const BulletinsRoutes = lazy(() => import("@/layouts/BulletinsRoutes"));
// export const AcompteSalary = lazy(() => import("pag_collabo/validations/acomptes/AcompteSalary"));
export const AcompteAddModal = lazy(() => import("pag_collabo/salary-items/AcompteAddModal"));
export const AcompteDetailsModal = lazy(() => import("pag_collabo/salary-items/AcompteDetailsModal"));
export const SalaryAcompte = lazy(() => import("pag_collabo/salary-items/SalaryAcompte"));
export const Indemnity = lazy(() => import("pag_collabo/salary-items/Indemnity"));
export const AllIndemnity = lazy(() => import("pag_collabo/compensation-elements/regulation/AllIndemnity"));
export const RegulationUserDetailView = lazy(() => import("pag_collabo/compensation-elements/regulation/RegulationUserDetailView"));

export const Deduction = lazy(() => import("pag_collabo/salary-items/Deduction"));
export const PaySheet = lazy(() => import("comp_common/pdfs/templates/paySheet/PaySheet"));
export const CnpsHiringNotice = lazy(() => import("comp_common/pdfs/templates/CnpsHiringNotice"));
export const ImmatriculationRequest = lazy(() => import("comp_common/pdfs/templates/ImmatriculationRequest"));
export const PersonnelCensus = lazy(() => import("comp_common/pdfs/templates/PersonnelCensus"));
export const AbsenceForm = lazy(() => import ("pag_collabo/absence-and-leaves/absence-leave-monitoring/forms/AbsenceForm"));
export const Page404 = lazy(() => import("@/pages/error-pages/Page404"));

export const SettingsRoutes = lazy(() => import("@/layouts/SettingsRoutes"));
export const CompanyIdentity = lazy(() => import("pag_collabo/settings/companyIdentity/CompanyIdentity"));
export const AccessManagement = lazy(() => import("pag_collabo/settings/accessManagement/AccessManagement"));
export const BankInfo = lazy(() => import("pag_collabo/settings/companyIdentity/bank-info/BankInfo"));
export const Delegate = lazy(() => import("pag_collabo/settings/companyIdentity/delegate/Delegate"));
export const GeneralInfo = lazy(() => import("pag_collabo/settings/companyIdentity/general-info/GeneralInfo"));

export const FeatNotAvailable = lazy(() => import("@/pages/rh/add-collabo/FeatNotAvailable"));

// User registration
export const RegistrationProcessSummary = lazy(() => import("pag_collabo/collabo-add-form/registration-process-summary/RegistrationProcessSummary"));

export const IdentityForms = lazy(() => import("pag_collabo/collabo-add-form/IdentityForms"));
export const AddressForm = lazy(() => import("pag_collabo/collabo-add-form/identity-forms/AddressForm"));
export const PersonalInfoForm = lazy(() => import("pag_collabo/collabo-add-form/identity-forms/PersonalInfoForm"));
export const PublicInfoForm = lazy(() => import("pag_collabo/collabo-add-form/identity-forms/PublicInfoForm"));

export const MotifForm = lazy(() => import("pag_collabo/collabo-contract-end-form/MotifForm"));
export const IndemnityForm = lazy(() => import("pag_collabo/collabo-contract-end-form/IndemnityForm"));
export const ResumeForm = lazy(() => import("pag_collabo/collabo-contract-end-form/ResumeForm"));
export const ContractEndForm = lazy(() => import("pag_collabo/collabo-contract-end-form/ContractEndForm"));
export const EndContractResume = lazy(() => import("pag_collabo/collabo-details/collabo-menu/contrat/end-contract-details/EndContractResume"));
export const CancelEndContract = lazy(() => import("pag_collabo/collabo-details/collabo-menu/contrat/end-contract-details/CancelEndContract"));

export const ContractForms = lazy(() => import("pag_collabo/collabo-add-form/ContractForms"));
export const ContractTypeForm = lazy(() => import("pag_collabo/collabo-add-form/contract-forms/ContractTypeForm"));
export const WorkClassificationForm = lazy(() => import("pag_collabo/collabo-add-form/contract-forms/WorkClassificationForm"));
export const ContractDurationForm = lazy(() => import("pag_collabo/collabo-add-form/contract-forms/ContractDurationForm"));

export const WorkDetailsForms = lazy(() => import("pag_collabo/collabo-add-form/WorkDetailsForms"));
export const WorkTimeForm = lazy(() => import("pag_collabo/collabo-add-form/work-time-forms/WorkTimeForm"));
export const BasicSalaryForm = lazy(() => import("pag_collabo/collabo-add-form/work-time-forms/BasicSalaryForm"));

export const WorkRecoveryForms = lazy(() => import("pag_collabo/collabo-add-form/WorkRecoveryForms"));
export const BalanceForm = lazy(() => import("pag_collabo/collabo-add-form/work-recovery-forms/BalanceForm"));
export const BasicRemunerationForm = lazy(() => import("pag_collabo/collabo-add-form/work-recovery-forms/BasicRemunerationForm"));

export const CollaboTeamRoutes = lazy(() => import("@/pages/collabo/teams/collabo-directory/CollaboTeamRoutes"));
export const CollaboTeam = lazy(() => import("@/pages/collabo/teams/collabo-directory/CollaboTeam"));
export const CollaboTeamDetails = lazy(() => import("@/pages/collabo/teams/collabo-directory/CollaboTeamDetails"));

export const OrganigrammeChart = lazy(() => import("pag_collabo/teams/organization-chart/OrganigrammeChart"));
export const UserAwardsList = lazy(() => import("pag_collabo/compensation-elements/bonus/UserAwardsList"));
export const UserRegulationsList = lazy(() => import("pag_collabo/compensation-elements/regulation/UserRegulationsList"));
export const AddUserToRegulationForm = lazy(() => import("pag_collabo/compensation-elements/regulation/AddUserToRegulationForm"));
export const DeleteUserFromRegulationForm = lazy(() => import("pag_collabo/compensation-elements/regulation/DeleteUserFromRegulationForm"));
export const CollaboRewardingForm = lazy(() => import("pag_collabo/compensation-elements/bonus/CollaboRewardingForm"));

export const AddFileFormModal = lazy(() => import("pag_collabo/folders/modals/AddFileFormModal"));
export const AddFolderFormModal = lazy(() => import("pag_collabo/folders/modals/AddFolderFormModal"));
export const RnDocFormModal = lazy(() => import("pag_collabo/folders/modals/RnDocFormModal"));
export const ShareDocFormModal = lazy(() => import("pag_collabo/folders/modals/ShareDocFormModal"));
export const ChangeDocPermsModal = lazy(() => import("pag_collabo/folders/modals/ChangeDocPermsModal"));
export const DocViewersModal = lazy(() => import("pag_collabo/folders/DocViewersModal"));

export const Container = lazy(() => import("pag_collabo/folders/Container"));

// HR Indicators routes
export const HRIndicators = lazy(() => import("@/pages/rh/indicators/index"));
export const Overview = lazy(() => import("@/pages/rh/indicators/Overview"));
export const Collabos = lazy(() => import("@/pages/rh/indicators/Collabos"));
export const LeavesAndAbsences = lazy(() => import("@/pages/rh/indicators/LeavesAndAbsences"));
export const Contract = lazy(() => import("@/pages/rh/indicators/Contract"));

