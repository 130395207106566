import React, {useEffect, useState} from "react";
import {Button, CustomTooltip, Dropdown} from "@/components/common";
import AdvancedBadge from "@/components/common/badge/AdvancedBadge";
import StaticDatePickerLandscape from "@/components/common/static-datepicker/StaticDatePickerLandscape";
import DashboardChart from "@/components/common/chart/DashboardChart";
import {
  capitalize,
  formatMoney,
  getChargesPatronale,
  getCollaboPerSex,
  getEffectifCollabo,
  getPyramideAgeData,
} from "@/utils/utils";
import {User} from "@/types/UserType";
import moment from "moment/min/moment-with-locales";
import {useAppSelector} from "@/hooks/reduxToolkitHook";
import useAPI from "@/hooks/useAPI";
import {getAbsencesStatistics, getSettingsConstants} from "@/requests/api";
import {AbsencesStatistics} from "@/types/api/UserAbsenceType";
import DayEvents from "@/pages/dashboard/DayEvents";
import DashboardPieChart from "./DashboardPieChart";
import ChartLegend, {ILegend} from "./ChartLegend";
import {PaymentsDates, SettingsType} from "@/types/SettingsType";
import {FemaleRounded, Info, MaleRounded} from "@mui/icons-material";
import {SettingsSlugs} from "comp_common/pdfs/templates/paySheet/utils/constants";
import {formatNextPaymentDate, getPaymentsDates} from "@/pages/dashboard/utils/utils";
import {nextClosingDate} from "pag_collabo/payslip/utils/utils";

type ChartValueType = {
  color: string[];
  max?: number;
  data: any[];
  step: number;
  option?: string;
  legend?: ILegend[],
  barSize?: number
};


const getClosingDateFromSettings = async () => getSettingsConstants("closing-date").then(res => res[0] as SettingsType<{ start: number, end: number }>);

const getTotalChargePatronale = (users: User[]) => {
  if (users.length > 0) {
    let totalSalary: any = 0;
    users.forEach((element: User) => {
      if (element.contract?.salary !== undefined) {
        totalSalary += getChargesPatronale("total_charge", element.contract?.salary, 2) + element.contract?.salary;
      }
    });
    totalSalary = Math.ceil(totalSalary);
    return totalSalary;
  }
  return 0;
};

export const getCNPSContribution = (users: User[], kind: "salarial" | "patronale") => {
  if (users.length > 0) {
    let totalContribution: any = 0;
    users.forEach((element: User) => {
      if (element.contract?.salary !== undefined) {
        const risque_pro = 2;
        kind == "salarial" ?
            totalContribution += (element.contract?.salary * 3.6 / 100)
            :
            totalContribution += (element.contract?.salary * (risque_pro + 15.4) / 100); //Prestation fam 9% + Assurance Retraite 6.5%
      }
    });
    totalContribution = Math.ceil(totalContribution);
    return totalContribution;
  }
  return 0;
};

const selectOptions = [
  { value: "pyramide-ages", label: "Pyramide des âges", step: 5 },
  { value: "effectif", label: "Effectif collaborateurs", step: 5 },
];


const Dashboard = () => {
  const { users } = useAppSelector(state => state.users);
  const { paysheetsGenerationJob } = useAppSelector(state => state.paysheets);
  const { targetMonth } = useAppSelector(state => state.targetMonth);
  const [dateValue, setDateValue] = useState<Date | null>(new Date());
  const { data: closingDate } = useAPI(() => getClosingDateFromSettings().then(res => res.content));
  const { data: absencesStat } = useAPI<AbsencesStatistics[]>(() => getAbsencesStatistics());
  // const { data: paysheets } = useAPI<any[]>(() => getPaysheets({year1: moment().subtract(4, 'month').year(), month1: moment().subtract(4, 'month').month()+1, year2: moment().year(), month2: moment().month() }));
  const { data: paymentsDates } = useAPI<PaymentsDates>(() => getSettingsConstants(SettingsSlugs.importantDates).then(res => res[0].content.payments));

  const closingPeriods = nextClosingDate(targetMonth, closingDate, "DD/MM/YYYY");

  const [paysheets, setPaysheets] = useState<any[]>([]);
  const onDateValueChange = (newValue: any) => {
    setDateValue(newValue);
  };


  const importantDates = () => {
    return [
      {
          eventName: "Génération des bulletins de salaire",
        date: closingDate ? `${closingPeriods.startPeriod.format("DD/MM/YYYY")} - ${closingPeriods.endPeriod.format("DD/MM/YYYY")}` : "--",
      },
      {
          eventName: "Paiement cotisations sociales (CNAM, CNPS)",
        // paymentsDates && users ? formatNextPaymentDate(users.length < paymentsDates.cnps.limit_employees ? getPaymentsDates(paymentsDates.cnps.less) : getPaymentsDates(paymentsDates.cnps.more)) : "--"
        date: paymentsDates && users ? formatNextPaymentDate(users.length < paymentsDates.cnps.limit_employees ? getPaymentsDates(paymentsDates.cnps.less) : getPaymentsDates(paymentsDates.cnps.more)) : "--",
      },
      {
        eventName: "Paiement impôts",
        date: paymentsDates ? formatNextPaymentDate(getPaymentsDates(paymentsDates.tax)) : "--",
      },
    ];
  };

  const pieChartData = [
      {name: "CCC", fullName: "Cotisation CNAM-CMU", value: getCNPSContribution(users, "salarial"), color: "#399AB3"},
      {
          name: "CF",
          fullName: "Cotisation FDFP (TA -FPC)",
          value: getCNPSContribution(users, "salarial"),
          color: "#E4CCFF"
      },
      {name: "CC", fullName: "Cotisation CNPS", value: getCNPSContribution(users, "patronale"), color: "#AFF4C6"},
  ];


  const [dropDownSelectedOption, setDropDownSelectedOption] =
    useState<ChartValueType>({
      ...getPyramideAgeData(users),
      step: selectOptions[0].step,
      option: selectOptions[0].label,
      legend: [{label: "Nombre de collaborateurs", color: "#6bd8d5"}],
      barSize: 16,
    });

  useEffect(() => {
    setDropDownSelectedOption(
      {
        ...getPyramideAgeData(users),
        step: selectOptions[0].step,
        option: selectOptions[0].label,
        legend: [{label: "Nombre de collaborateurs", color: "#6bd8d5"}],
        barSize: 16,
      },
    );

  }, [users]);

  const handleDropdownChange = (v: any) => {
    const step =
      selectOptions[
        selectOptions.findIndex((elt) => elt.value === v.target.value)
        ].step;
    const option =
      selectOptions[
        selectOptions.findIndex((elt) => elt.value === v.target.value)
        ].label;

    switch (v.target.value) {

      case "effectif":
        setDropDownSelectedOption({
          ...getEffectifCollabo(users),
          step: step,
          option,
          legend: [{ label: "Nombre d'homme", color: "#6bd8d5" }, {
            label: "Nombre de femme",
            color: "#f340b5",
          }, { label: "Effectif total", color: "#399AB3" }],
          barSize: 12,
        });
        break;

      case "pyramide-ages":
        setDropDownSelectedOption({
          ...getPyramideAgeData(users),
          step: step,
          option,
          legend: [{ label: "Nombre de collaborateurs", color: "#6bd8d5" }],
          barSize: 16,
        });
        break;

      default:
        break;
    }
  };

  const nbAbsents = absencesStat ? absencesStat.reduce((prev, curr) => prev + curr.count, 0) : 0;
  const greeter = () => {
    const currentHour = moment().format("HH")
    if (currentHour < 12) {
      return `Bonjour!`
    } else if (currentHour >= 12 && currentHour < 17) {
      return `Bon après-midi!`
    } else {
      return `Bonsoir!`
    }
  }
  return (
    <>
      <div className="grid grid-cols-12 h-full overflow-y-hidden">
        <div className="col-span-12 lg:col-span-8 2xl:col-span-9 overflow-y-auto">
          <div className="m-10">
            <h1 className="text--titlePage mb-3 text-blue-700">{greeter()} 👋</h1>
            <p className="text-s4 text-blue-600 mb-4">
              {
                `Nous sommes le ${moment().format('dddd DD MMMM YYYY')}`
              }
            </p>
            <div className="grid grid-cols-12 gap-6 mb-7">
              <div className="col-span-12 sm:col-span-6 2xl:col-span-3 card">
                <h1 className="text--S1 text-blue-800 mb-3">
                  {users?.length}
                </h1>
                <h2 className="text--S4 text-blue-700 mb-2">Collaborateurs</h2>
                <div className="flex flex-row justify-between space-y-2 text-blue-600">
                  <div className="flex space-x-2 items-center">
                      <span className="bg-[#D4F7E8] rounded p-1">
                        <MaleRounded sx={{ color: "#0A9B5D" }} fontSize="small" />
                      </span>
                    <p className="grid leading-4	">
                      <span>{getCollaboPerSex("M", users)} </span>
                      <span>Hommes</span>
                    </p>
                  </div>
                  <div className="flex space-x-2 items-center">
                      <span className="bg-[#FDE4FD] rounded p-1">
                        <FemaleRounded sx={{ color: "#EF26EF" }} fontSize="small" />
                      </span>
                    <p className="grid leading-4">
                      <span>{getCollaboPerSex("F", users)}</span>
                      <span>Femmes</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 2xl:col-span-3 card">
                <h1 className="text--S1 text-blue-800 mb-3">
                  {nbAbsents}
                </h1>
                <h2 className="text--S4 text-blue-700 mb-2">Absents</h2>
                <div className="flex flex-row justify-between items-center text-blue-600">
                  <p>Taux d'absences</p>
                  <AdvancedBadge
                    className="bg-[#E3FCEF] text--S6 text-green-800  text--S4 py-0 px-2 rounded">
                    {absencesStat?.length ? ((nbAbsents / users.length) * 100).toFixed(2) : 0}%
                  </AdvancedBadge>

                </div>
              </div>
              <div className="col-span-12 2xl:col-span-6 card bg-gradient-blue-270deg text-white">
                <div className="flex items-center">
                  <h2 className="text--S4">
                    Coût salarial global
                  </h2>
                  <CustomTooltip title={
                    <p>
                      Ce montant est une <ins>estimation</ins> du <strong>coût global</strong> de vos charges
                      salariales, si aucune <ins>prime,</ins> aucun <ins> avantage, </ins>
                      ainsi qu'aucune <ins>déduction</ins> ne s'ajoutent aux salaires de vos collaborateurs
                    </p>}>
                    <Info className={"text-white ml-3"} fontSize="small"/>
                  </CustomTooltip>
                </div>

                  <div className="space-x-1">
                    <span className="text--S1">{formatMoney(getTotalChargePatronale(users))}</span>
                    <span className="text-lg">Fcfa</span>
                  </div>

                  <div className="">{capitalize(moment().format("MMMM YYYY"))}</div>
                </div>
                {/*
              <div className="col-span-12 rounded-xl py-2 px-4 bg-white">
                <div className="flex flex-row justify-between items-center mb-3 ">
                  <h1 className="text--S4 text-blue-800">CNPS</h1>
                  <p className="text-blue-700">
                    Prochain versement: {getPaymentDueDate("02/15/2022", users.length)}
                  </p>
                </div>
                <p className="text-gray-500">
                  La date du paiement de l'échéance de vos cotisations sociales s'affiche selon si vous êtes
                  au mensuel ou au trimestriel. <br />
                  <span className="link--blue">En savoir plus</span>
                </p>
              </div>
              <div className="col-span-12 rounded-xl py-4 px-4 bg-white">
                <div className="flex flex-row justify-between items-center mb-3 ">
                  <h1 className="text--S4 text-blue-800">
                    Les demandes de vos collaborateurs (4)
                  </h1>
                </div>
                <p className="text-gray-500">
                  Vous pouvez accéder ici aux demandes émises par vos collaborateurs.
                </p>
              </div>
              */}
                <div className="col-span-12 2xl:col-span-6 grid gap-3">
                    <div className="card space-y-4">
                        <h2 className="text--S4 text-blue-700">Dates clées</h2>
                        <div className="space-y-2">
                            {importantDates().map((item, index) => (
                                <div key={`${index}${item.date.toString()}`}
                                     className="flex justify-between body--default">
                                    <p className="!text-gray-600">{item.eventName}</p>
                                    <p className="!text-blue-500 fon">{item.date}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="card space-y-4">
                        <h2 className="text--S4 text-blue-700">Mon quotidien RH</h2>
                        <div className="flex justify-between items-center">
                            <div>
                                <p><span className="text-blue-500">39</span> actions RH à traiter</p>
                                <p className="text-danger font-medium">dont 12 en retard !</p>
                            </div>
                            <Button>
                                Voir les actions
                            </Button>

                        </div>
                </div>
              </div>

                <div className="col-span-12 2xl:col-span-6 card space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <h2 className="text--S4 text-blue-700">Charges sociales</h2>
                    <CustomTooltip title={
                      <p>
                        Ces calculs sont une <ins>estimation</ins> des <strong>charges sociales</strong> liées à votre
                        entreprise au mois de {moment().format("MMMM YYYY")}, si
                        aucune <ins>prime,</ins> aucun <ins> avantage, </ins>
                        ainsi qu'aucune <ins>déduction</ins> ne s'ajoutent aux salaires de vos collaborateurs
                      </p>}>
                      <Info className={"text-blue-700 ml-3"} fontSize="small"/>
                    </CustomTooltip>
                  </div>
                  <p className="capitalize text-gray-500">{moment().format("MMMM YYYY")} </p>
                </div>
                <div className="flex justify-between">
                  <div className="w-1/2 h-44">
                    <DashboardPieChart data={pieChartData}/>
                  </div>
                  <div className="w-auto flex flex-col justify-center space-y-3">
                    {pieChartData.map((item, index) => (
                      <div key={index} className="flex gap-4">
                        <span className="w-2 h-7" style={{backgroundColor: item.color}}/>
                        <div className="text-xs font-medium text-gray-500">
                          <p className="">{item.name} : {item.fullName}</p>
                          <p className="font-semibold"> {formatMoney(item.value)} FCFA</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-span-12 card">
                <div className="flex flex-wrap items-center justify-between gap-6 mb-6">
                  <p className="text--S4 text-gray-700">{dropDownSelectedOption.option}</p>
                  <div className="flex lg:justify-end">
                    <Dropdown
                      placeholder="Filtres"
                      options={selectOptions}
                      onChange={handleDropdownChange}
                    />
                  </div>
                </div>
                <hr className="mb-4 h-[2px] bg-gray-100"/>
                <div className="chart mx-8 mt-14 pt-3 pb-6">
                  <DashboardChart
                    chartData={dropDownSelectedOption.data}
                    yaProps={{tickCount: dropDownSelectedOption.step}}
                    bProps={{colors: dropDownSelectedOption.color, barSize: dropDownSelectedOption.barSize}}
                  />

                  <ChartLegend items={dropDownSelectedOption.legend}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 2xl:col-span-3 hidden lg:block pt-8 bg-white h-full overflow-y-auto">
          <div>
            <StaticDatePickerLandscape
              value={dateValue}
              onChange={onDateValueChange}
            />
          </div>
          <DayEvents selectedDate={dateValue}/>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
